import {NgModule} from '@angular/core';
import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {ProgressPreloadingStrategy} from './progress-preloading-strategy';
import {MsalRedirectComponent} from '@azure/msal-angular';

const routes: Routes = [
  {path: 'pages', loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule)},
  {path: '', redirectTo: 'pages', pathMatch: 'full'},
  {path: '**', redirectTo: 'pages'},
  {path: 'auth', component: MsalRedirectComponent},
];

const config: ExtraOptions = {
  useHash: false,
  enableTracing: false,
  preloadingStrategy: ProgressPreloadingStrategy
}

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [ProgressPreloadingStrategy],
})
export class AppRoutingModule {
}
