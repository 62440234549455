export * from './document.service';
import { DocumentService } from './document.service';
export * from './documentArea.service';
import { DocumentAreaService } from './documentArea.service';
export * from './documentMarkup.service';
import { DocumentMarkupService } from './documentMarkup.service';
export * from './documentSet.service';
import { DocumentSetService } from './documentSet.service';
export * from './documentStorage.service';
import { DocumentStorageService } from './documentStorage.service';
export * from './documentStore.service';
import { DocumentStoreService } from './documentStore.service';
export * from './documentTag.service';
import { DocumentTagService } from './documentTag.service';
export * from './documentTagType.service';
import { DocumentTagTypeService } from './documentTagType.service';
export * from './documentTagTypeValue.service';
import { DocumentTagTypeValueService } from './documentTagTypeValue.service';
export * from './documentVersion.service';
import { DocumentVersionService } from './documentVersion.service';
export * from './drawing.service';
import { DrawingService } from './drawing.service';
export * from './environment.service';
import { EnvironmentService } from './environment.service';
export * from './metadata.service';
import { MetadataService } from './metadata.service';
export const APIS = [DocumentService, DocumentAreaService, DocumentMarkupService, DocumentSetService, DocumentStorageService, DocumentStoreService, DocumentTagService, DocumentTagTypeService, DocumentTagTypeValueService, DocumentVersionService, DrawingService, EnvironmentService, MetadataService];
