import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { DocuwerxConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { DocumentService } from './api/document.service';
import { DocumentAreaService } from './api/documentArea.service';
import { DocumentMarkupService } from './api/documentMarkup.service';
import { DocumentSetService } from './api/documentSet.service';
import { DocumentStorageService } from './api/documentStorage.service';
import { DocumentStoreService } from './api/documentStore.service';
import { DocumentTagService } from './api/documentTag.service';
import { DocumentTagTypeService } from './api/documentTagType.service';
import { DocumentTagTypeValueService } from './api/documentTagTypeValue.service';
import { DocumentVersionService } from './api/documentVersion.service';
import { DrawingService } from './api/drawing.service';
import { EnvironmentService } from './api/environment.service';
import { MetadataService } from './api/metadata.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class DocuwerxApiModule {
    public static forRoot(configurationFactory: () => DocuwerxConfiguration): ModuleWithProviders<DocuwerxApiModule> {
        return {
            ngModule: DocuwerxApiModule,
            providers: [ { provide: DocuwerxConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: DocuwerxApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('DocuwerxApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
