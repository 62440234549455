import { NgModule } from '@angular/core';
import { SeparateCamelCasePipe } from './separate-camel-case.pipe';
import {LengthConverterPipe} from './length-converter.pipe';

@NgModule({
  imports: [],
  declarations: [
    SeparateCamelCasePipe,
    LengthConverterPipe,
  ],
  exports: [
    SeparateCamelCasePipe,
    LengthConverterPipe,
  ],
  providers: [LengthConverterPipe],
})
export class ApplicationPipesModule {
}
